import React from 'react'
import { popularRoles } from '../../utilities/constants'

const PopularRoles = ({ setJobRole }) => {
    return (
        <div className='mt-5'>
            <h3 className='text-[#90BEED]'>Popular Roles</h3>
            <div className='flex gap-2 flex-wrap mt-2 h-[150px] md:h-fit overflow-y-scroll takeaway'>
                {popularRoles?.map((item, index) => (
                    <div key={index} onClick={() => setJobRole(item)} className='bg-secondary_background text-sm text-[#9ABFE5] py-1.5 px-2.5 rounded-lg h-fit cursor-pointer'>
                        {item}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default PopularRoles