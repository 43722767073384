import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchSkillName = createAsyncThunk(
    'fetchSkillName/skillDetailsSlice',
    async ({ skill_slug }) => {
        const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:ZZCtun0K/fetch_skill_name_for_personalize', {
            params: {
                skill_slug
            }
        })
        return response.data
    }
)

export const fetchRelatedJobs = createAsyncThunk(
    'fetchRelatedJobs',
    async ({ subject }) => {
        try {
            const response = await axios.post('https://certified-backend.learntube.ai/job_names', {
                subject
            })
            return response.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const fetchAnalysisReport = createAsyncThunk(
    'fetchAnalysisReport',
    async ({ difficultyLevel, correctCount, incorrectCount, subject }) => {
        try {
            const response = await axios.post('https://certified-backend.learntube.ai/personalize_ranks', {
                difficultyLevel, correctCount, incorrectCount, subject
            })
            return response.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

const initialState = {
    skillName: "",
    skillLevel: "",
    skillDescription: "",
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    whyWanToLearn: '',
    showCourseLoader: false,
    visitedAnalysisPage: false,
    relatedJobs: [],
    savedResponse: false,
    analysisReport: {}
}

const skillDetailsSlice = createSlice({
    name: "skillDetails",
    initialState,
    reducers: {
        setUserSkillDetails: (state, action) => {
            const { skillName, skillLevel, skillDescription, utm_source, utm_medium, utm_campaign } = action.payload;
            state.skillName = skillName;
            state.skillLevel = skillLevel;
            state.skillDescription = skillDescription
            state.utm_source = utm_source
            state.utm_medium = utm_medium
            state.utm_campaign = utm_campaign
        },
        setUserSkillName: (state, action) => {
            const { skillName } = action.payload;
            state.skillName = skillName;
        },
        setUserSkillNameAndSkillLevel: (state, action) => {
            const { skillName, skillLevel } = action.payload
            state.skillName = skillName
            state.skillLevel = skillLevel
        },
        setUserSkill: (state, action) => {
            const { skillName, skillLevel, skillDescription } = action.payload
            state.skillName = skillName
            state.skillLevel = skillLevel;
            state.skillDescription = skillDescription
        },
        setUserSkillLevel: (state, action) => {
            const { skillLevel, skillDescription } = action.payload
            state.skillLevel = skillLevel
            state.skillDescription = skillDescription
        },
        setUTMDetails: (state, action) => {
            const { utm_source, utm_medium, utm_campaign } = action.payload
            state.utm_source = utm_source
            state.utm_campaign = utm_campaign
            state.utm_medium = utm_medium
        },
        setShowCourseLoader: (state, action) => {
            state.showCourseLoader = action.payload
        },
        setVisitedAnalysisPage: (state, action) => {
            state.visitedAnalysisPage = action.payload
        },
        setSavedResponse: (state, action) => {
            state.savedResponse = action.payload
        },
        setWhyUserWantToLearn: (state, action) => {
            state.whyWanToLearn = action.payload
        }
    },
    extraReducers: (builder)=> {
        builder.addCase(fetchSkillName.fulfilled, (state, action) => {
            state.skillName = action?.payload?.['skill-name']
        })
        builder.addCase(fetchRelatedJobs.fulfilled, (state, action) => {
            state.relatedJobs = action.payload
        })
        builder.addCase(fetchAnalysisReport.fulfilled, (state, action) => {
            state.analysisReport = action.payload
        })
    }

})

export const { setUserSkillName, setUserSkill, setUTMDetails, setUserSkillLevel, setUserSkillDetails, setShowCourseLoader, setVisitedAnalysisPage, setUserSkillNameAndSkillLevel, setSavedResponse, setWhyUserWantToLearn } = skillDetailsSlice.actions
export const selectSkillName = (state) => state.skillDetails?.skillName
export const selectUserSkillDetails = (state) => state.skillDetails
export const selectShowCourseLoader = (state) => state.skillDetails.showCourseLoader
export const selectVisitedAnalysisPage = (state) => state.skillDetails.visitedAnalysisPage
export const selectRelatedJobs = (state) => state.skillDetails.relatedJobs
export const selectSavedResponse = (state) => state.skillDetails.savedResponse
export const selectAnalysisReport = (state) => state.skillDetails.analysisReport
export const selectWhyWanToLearn = (state) => state.skillDetails.whyWanToLearn
export default skillDetailsSlice.reducer