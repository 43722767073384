import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const checkUserTrial = createAsyncThunk(
    'checkUserTrial',
    async ({ uuid, subject, email }) => {
        try {
            const response = await axios.post('https://xgfy-czuw-092q.m2.xano.io/api:ZZCtun0K/global/trial_user_check', {
                uuid, subject, email
            })
            return response.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const checkTrialPeriod = createAsyncThunk(
    'checkTrialPeriod',
    async ({ email, subject }) => {
        try {
            const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:ZZCtun0K/global/user_account_status/banner', {
                params: {
                    email, subject
                }
            })
            return response.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const checkCertificateStatus = createAsyncThunk(
    'checkCertificateStatus',
    async ({  email, subject }) => {
        try {
            const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:ZZCtun0K/global/user_account_status/certificate', {
                params: {
                    email, subject
                }
            })
            return response.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

const initialState = {
    name: "",
    isScholarshipApplied: false,
    lastPlayedLesson: 0,
    analysisPageButton: false,
    lastVisited: '',
    trialUser: {
        is_trial_user: false
    },
    trialPeriod: {
        show_banner: false
    },
    checkCertificateStatus: {}
}

const nameSlice = createSlice({
    name: "name",
    initialState,
    reducers: {
        setUserName: (state, action) => {
            const { name } = action.payload;
            state.name = name; // Update the 'name' field in the state
        },
        setScholarshipDetails: (state, action) => {
            const { scholarship } = action.payload;
            state.isScholarshipApplied = scholarship; // Update the 'isScholarshipApplied' field in the state
        },
        setLastWatchedLesson: (state, action) => {
            const { lessonId } = action.payload
            state.lastPlayedLesson = lessonId
        },
        setAnalysisPageButtonClicked: (state, action) => {
            state.analysisPageButton = action.payload
        },
        setLastVisitedUrl: (state, action) => {
            state.lastVisited = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(checkUserTrial.fulfilled, (state, action) => {
            state.trialUser = action.payload
        })
        builder.addCase(checkTrialPeriod.fulfilled, (state, action) => {
            state.trialPeriod = action.payload
        })
        builder.addCase(checkCertificateStatus.fulfilled, (state, action) => {
            state.checkCertificateStatus = action.payload
        })
    }
});

export const { setUserName, setScholarshipDetails, setLastWatchedLesson, setAnalysisPageButtonClicked, setLastVisitedUrl } = nameSlice.actions;
export const selectUserName = (state) => state.name.name;
export const selectScholarship = (state) => state.name.isScholarshipApplied;
export const selectLastPlayedLesson = (state) => state.name.lastPlayedLesson
export const selectAnalysisPageButtonClicked = (state) => state.name.analysisPageButton
export const selectLastVisited = (state) => state.name.lastVisited
export const selectUserTrial = (state) => state.name.trialUser
export const selectTrialPeriod = (state) => state.name.trialPeriod
export const selectCertificateStatus = (state) => state.name.checkCertificateStatus
export default nameSlice.reducer;
